<template>
  <Modal v-model="setCollectionAccountModal" :title="bindingTitle" width="550" class-name="setCollectionAccountStyle"
    :transfer="false" :mask-closable="false" @on-visible-change="setCollectionAccountChange">
    <Form ref="setCollectionAccountForm" :model="setCollectionAccountForm" :rules="ruleValidate" :label-width="120" @submit.native.prevent>
      <!--收款方式-->
      <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="bindingTalg">
        <Form-item :label="$t('key1002092')" prop="payType" class="reset_form_item">
          <RadioGroup v-model="setCollectionAccountForm.payType" class="radioGroup_reset_style">
            <Radio :label="item.payType" v-for="(item,index) in payList" :key="index">
              <img :src="item.logoImg" width="130" :height="item.payType === 1 ? 47 : 43" :class="item.payType === 1 ? 'mr20' : ''" alt=""/>
            </Radio>
          </RadioGroup>
        </Form-item>
      </Col>
      <!--银行卡-->
      <Row style="width: 90%;" v-if="setCollectionAccountForm.payType === 1">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002093')" prop="name">
            <Input v-model.trim="setCollectionAccountForm.name" :placeholder="$t('key1005681')"></Input>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002094')" prop="issuingBank">
            <Input v-model.trim="setCollectionAccountForm.issuingBank" :placeholder="$t('key1005682')"></Input>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002095')" prop="payAccountNumber">
            <Input v-model.trim="setCollectionAccountForm.payAccountNumber" :placeholder="$t('key1005683')"></Input>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002096')" prop="idCard">
            <Input v-model.trim="setCollectionAccountForm.idCard" :placeholder="$t('key1005684')"></Input>
          </Form-item>
        </Col>
      </Row>
      <!--支付宝-->
      <Row style="width: 90%;" v-if="setCollectionAccountForm.payType === 2">
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002097')" prop="payAccountNumber">
            <Input v-model.trim="setCollectionAccountForm.payAccountNumber" :placeholder="$t('key1005685')"></Input>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002093')" prop="name">
            <Input v-model.trim="setCollectionAccountForm.name" :placeholder="$t('key1005686')"></Input>
          </Form-item>
        </Col>
        <Col :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol">
          <Form-item :label="$t('key1002098')" prop="idCard">
            <Input v-model.trim="setCollectionAccountForm.idCard" :placeholder="$t('key1005687')"></Input>
          </Form-item>
        </Col>
      </Row>
    </Form>
    <template #footer>
      <Button @click="setCollectionAccountModal = false">{{ $t('key1000098') }}</Button>
      <Button type="primary" @click="setCollectionAccountBtn">{{ $t('key1005688') }}</Button>
    </template>
  </Modal>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";

export default {
  name: "setCollectionAccountModal",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      setCollectionAccountModal: false,
      setCollectionAccountForm: {
        payType: 1, // 支付方式
        name: '', // 开户人姓名
        issuingBank: '', // 开户行
        payAccountNumber: '', // 银行卡号
        idCard: '', // 身份证号
      },
      ruleValidate: {
        payAccountNumber: [
          {required: true, validator: v.validateEmailOrMobile(), trigger: 'blur'}
        ],
        name: [
          {required: true, validator: v.validateName(), trigger: 'blur'}
        ],
        idCard: [
          {required: true, validator: v.validateidCard(), trigger: 'blur'}
        ],
        issuingBank: [
          {required: true, message: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005682'), trigger: 'blur'}
        ]
      },
      bindingTalg: false,
      bindingTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005689'),
      ymsPayAccountId: null,
      payList: [
        {
          payType: 1,
          name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005690'),
          logoImg: require('@/assets/images/pay/bankCard.png'),
        },
        {
          payType: 2,
          name: '',
          logoImg: require('@/assets/images/pay/alipay.png'),
        }
      ],
    }
  },
  methods: {
    // 初始化数据
    initSetCollectionAccountData(data) {
      let v = this;
      if (Object.keys(data).length > 0) {
        v.setCollectionAccountForm = {
          payType: data.payType,
          name: data.name,
          issuingBank: data.payType === 1 ? data.issuingBank : '',
          payAccountNumber: data.payAccountNumber,
          idCard: data.idCard
        }
        v.bindingTitle = data.bindingTitle;
        v.bindingTalg = data.bindingTalg;
        v.ymsPayAccountId = data.ymsPayAccountId;
      }
      v.setCollectionAccountModal = true;
    },
    // 更换、绑定收款账户按钮
    setCollectionAccountBtn() {
      let v = this;
      let url = '';
      let method = '';
      let text = '';
      let query = {};
      // 更换
      if (v.bindingTalg) {
        url = api.put_ymsPayAccount_updateAccount;
        method = 'put';
        text = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005691');
        query = Object.assign({}, v.setCollectionAccountForm, {ymsPayAccountId: v.ymsPayAccountId})
      }
      // 绑定
      else {
        url = api.post_ymsPayAccount_bindAliPayAccount;
        method = 'post';
        text = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003417');
        query = Object.assign({}, v.setCollectionAccountForm);
      }
      if (v.setCollectionAccountForm.payType === 2) {
        let {issuingBank, ...obj} = query;
        query = JSON.parse(JSON.stringify(obj));
      }
      v.$refs['setCollectionAccountForm'].validate((valid, err) => {
        if (valid) {
          v.axios[method](url, query, {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(text);
              v.setCollectionAccountModal = false;
              v.$emit('updateData', true);
            }
          });
        }
      })
    },
    // 监听弹窗
    setCollectionAccountChange(value) {
      if (!value) {
        this.bindingTalg = false;
        this.ymsPayAccountId = null;
        this.$refs['setCollectionAccountForm'].resetFields();
      }
    },
    // 校验手机号/邮箱
    validateEmailOrMobile() {
      let v = this;
      let isMob = v.$regular.isPhone;
      let isMailbox = v.$regular.isMailbox;
      return function (rule, value, callback) {
        let payType = v.setCollectionAccountForm.payType;
        if (payType === 1) {
          if (!value) {
            return callback(new Error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005683')));
          }
        } else {
          if (!value) {
            return callback(new Error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005692')));
          } else if (!(isMob.test(value) || isMailbox.test(value))) {
            return callback(new Error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005474')));
          } else {
            callback();
          }
        }
        callback();
      };
    },
    // 校验开户人姓名
    validateName() {
      let v = this;
      return function (rule, value, callback) {
        let payType = v.setCollectionAccountForm.payType;
        let text = payType === 1 ? aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005681') : aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005686');
        if (!value) {
          return callback(new Error(text));
        } else {
          callback();
        }
        callback();
      }
    },
    // 校验证件号
    validateidCard() {
      let v = this;
      return function (rule, value, callback) {
        let payType = v.setCollectionAccountForm.payType;
        let text = payType === 1 ? aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005684') : aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005687');
        if (!value) {
          return callback(new Error(text));
        } else {
          callback();
        }
        callback();
      }
    }
  },
  watch: {
    'setCollectionAccountForm.payType': {
      handler(value) {
        let fieldlist = this.$refs['setCollectionAccountForm'].fields;
        let keyList = [];
        if (value === 1) {
          keyList = ['name', 'payAccountNumber', 'idCard'];
        } else {
          keyList = ['name', 'issuingBank', 'payAccountNumber', 'idCard'];
        }
        fieldlist.map((item, index) => {
          if (keyList.includes(item.prop)) {
            item.resetField();
            this.$forceUpdate();
          }
        });
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .setCollectionAccountStyle {
  .reset_form_item {
    .ivu-form-item-label {
      height: 40px;
      line-height: 40px;
      padding: 0;
    }
  }

  .radioGroup_reset_style {
    display: flex;

    label {
      display: flex;
      align-items: center;
    }

    .mr30 {
      margin-right: 30px;
    }
  }
}
</style>