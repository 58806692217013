<template>
  <div class="container_box details_box_styles">
    <div class="filter_box" v-for="item in payList">
      <div class="flex align-items-center justify-content-between">
        <div class="flex align-items-center">
          <img :src="item.logoImg" width="130" :height="item.payType === 1 ? 47 : 43" alt=""/>
          <span v-if="item.payType === 1 && !item.bindingTalg" class="mr2 ml2">{{ $t('key1005693') }}</span>
          <div class="ml20 flex flex-direction textStyle">
            <div v-if="item.bindingTalg">
              <p>{{ item.accountName + item.payAccountNumber }}</p>
              <p>{{ item.accountHolder + item.name }}</p>
            </div>
            <span v-else>{{ item.bindingText }}</span>
          </div>
        </div>
        <Button
          type="primary"
          v-if="getPermission('ymsPayAccount_bindAccount') || getPermission('ymsPayAccount_update')"
          @click="$refs['setCollectionAccount'].initSetCollectionAccountData(item)">
          {{ item.bindingTitle }}
        </Button>
      </div>
    </div>
    <!--变更日志-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1005694') }}</h2>
        </template>
        <div class="table_box">
          <Table
            highlight-row
            border
            max-height="550"
            :loading="tableLoading"
            :columns="tableColumns"
            :data="tableData">
          </Table>
          <Page
            v-if="!tableLoading"
            :total="total"
            @on-change="changePage"
            show-total
            :page-size="pageParams.pageSize"
            show-elevator
            :current="pageParams.pageNum"
            show-sizer
            @on-page-size-change="changePageSize"
            placement="top"
            :page-size-opts="pageArray">
          </Page>
        </div>
      </Card>
    </div>
    <!--更换、绑定收款账户弹窗-->
    <setCollectionAccountModal ref="setCollectionAccount" @updateData="initFun()"></setCollectionAccountModal>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from "@/components/mixin/common_mixin";
import setCollectionAccountModal from "@/views/supplier/components/accountManage/setCollectionAccountModal.vue";
import {getUserNameByUserId} from "@/utils/common";

export default {
  name: "collectionAccount",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      pageParams: {
        ymsPayAccountId: null,
        pageNum: 1,
        pageSize: 15
      },
      total: 0,
      tableColumns: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000219'),
          key: 'operationType',
          width: 120,
          align: 'center',
          render(h, params) {
            let text = '';
            let operationType = params.row.operationType;
            let jsonobj = params.row.originalRecord || params.row.changeRecord;
            let obj = JSON.parse(jsonobj);
            if (obj.payType === 1) {
              operationType === 1 ? text = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005695') : operationType === 2 ? text = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005696') : '';
            } else {
              operationType === 1 ? text = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005688') : operationType === 2 ? text = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005697') : '';
            }
            return h('span', text);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005698'),
          key: 'originalRecord ',
          align: 'center',
          minWidth: 280,
          render(h, params) {
            let content = '-';
            let obj = {};
            let originalRecord = params.row.originalRecord;
            let name = '';
            let payName = '';
            if (originalRecord) {
              obj = JSON.parse(originalRecord);
              if (obj.payType === 1) {
                name = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002095');
                payName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002106');
              } else {
                name = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002097');
                payName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002107');
              }
              content = [
                h('div', {style: 'textAlign: left'}, [
                  h('p', {class: 'mt5 mb3'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002092') + payName),
                  h('p', {class: 'mt3 mb3'}, name + obj.payAccountNumber),
                  h('p', {class: 'mt3 mb3'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002093') + obj.name)
                ])
              ];
            }
            return h('div', {class: 'flex align-items-center pl20 pr20'}, content)
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005699'),
          key: 'changeRecord',
          align: 'center',
          minWidth: 280,
          render(h, params) {
            let content = '-';
            let obj = {};
            let name = '';
            let payName = '';
            let changeRecord = params.row.changeRecord;
            if (changeRecord) {
              obj = JSON.parse(changeRecord);
              if (obj.payType === 1) {
                name = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002095');
                payName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002106');
              } else {
                name = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002097');
                payName = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002107');
              }
              content = [
                h('div', {style: 'textAlign: left'}, [
                  h('p', {class: 'mt5 mb3'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002092') + payName),
                  h('p', {class: 'mt3 mb3'}, name + obj.payAccountNumber),
                  h('p', {class: 'mt3 mb3'}, aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002093') + obj.name)
                ])
              ];
            }
            return h('div', {class: 'flex align-items-center pl20 pr20'}, content)
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005700'),
          key: 'updatedTime',
          align: 'center',
          minWidth: 180,
          render(h, params) {
            return h('div', v.$uDate.dealTime(params.row.updatedTime, 'fulltime'));
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000222'),
          key: 'updatedBy',
          align: 'center',
          minWidth: 160,
          render(h, params) {
            return h('div', getUserNameByUserId(params.row.updatedBy, v));
          }
        }
      ],
      tableData: [],
      originalPayList: [
        {
          accountName: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002095'),
          accountHolder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002093'),
          payType: 1,
          payAccountNumber: '', // 银行卡号
          name: '', // 开户人姓名
          logoImg: require('@/assets/images/pay/bankCard.png'),
          bindingTalg: false, // 是否绑定
          bindingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005701'),
          bindingTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005702'),
        },
        {
          accountName: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002097'),
          accountHolder: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002093'),
          payType: 2,
          payAccountNumber: '', // 支付宝账户
          name: '', // 开户人姓名
          logoImg: require('@/assets/images/pay/alipay.png'),
          bindingTalg: false, // 是否绑定, true 已绑定  false 未绑定
          bindingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005703'),
          bindingTitle: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005689'),
        }
      ],
      payList: [],
      bindingTalg: false,
    }
  },
  created() {
    this.initFun();
  },
  methods: {
    // 初始化接口数据
    initFun() {
      (async () => {
        await this.getUserName();
        await this.getAlipayAccount();
      })();
      this.pageParamsStatus = true;
    },
    // 获取绑定支付的数据
    getAlipayAccount() {
      let v = this;
      let list = [];
      v.payList = [];
      v.bindingTalg = false;
      if (v.getPermission('ymsPayAccount_query')) {
        v.tableLoading = true;
        v.axios.get(api.get_ymsPayAccount).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas || [];
            if (data.length > 0) {
              data.map((item) => {
                v.originalPayList.map((ele) => {
                  if (item.payType === ele.payType) {
                    v.bindingTalg = true;
                    v.pageParams.ymsPayAccountId = item.ymsPayAccountId;
                    ele.bindingTitle = aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005704');
                    ele.bindingTalg = true;
                    let obj = Object.assign({}, ele, item);
                    list.push(obj)
                  }
                })
              });
              if (list.length > 0) {
                v.payList = list;
                v.$forceUpdate();
              }
            } else {
              v.payList = JSON.parse(JSON.stringify(v.originalPayList));
            }
            v.$nextTick(() => {
              v.tableLoading = false;
            })
          }
        }).catch((err) => {
          v.tableLoading = false;
        });
      } else {
        v.gotoError();
      }
    },
    // 获取列表数据
    getList() {
      let v = this;
      v.tableLoading = true;
      v.tableData = [];
      v.axios.post(api.post_ymsPayAccountLog_query, v.pageParams).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          let data = response.data.datas;
          v.tableData = data.list || [];
          v.total = data.total;
        }
      });
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  },
  components: {
    setCollectionAccountModal
  }
}
</script>

<style lang="less" scoped>
.filter_box {
  padding: 20px 15px;

  .textStyle {
    font-size: 14px;
    color: #333;
  }
}

</style>